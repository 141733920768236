import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

export const AboutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  background: #fff;
  padding: 0px 75px 50px 75px;
  justify-content: center;
  @media (max-width: 990px) {
    padding: 0px 45px 30px 45px;
  }
  @media (max-width: 575px) {
    padding: 0px 25px 20px 25px;
  }
  a {
    color: ${themeGet('colors.textColor', '#292929')};
    font-weight: bold;
  }
  a:hover {
    color: ${themeGet('colors.yellow', '#F1873A')};
  }
`;

export const AboutPageTitle = styled.div`
  margin-bottom: 45px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    color: ${themeGet('colors.textColor', '#292929')};
    line-height: 1.53;
    margin-bottom: 10px;
    @media (max-width: 990px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;

export const AboutImage = styled.div`
  position: relative;
  overflow: hidden;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
  @media (max-width: 600px) {
    max-height: 0em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  padding-top: 90px;
  transition: 0.3s ease-in-out;
  @media (max-width: 1600px) {
    padding-top: 60px;
  }
  @media (max-width: 1024px) {
    padding-top: 20px;
  }
`;

export const AboutDetails = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  background-color: #fff;
  padding: 90px;
  transition: 0.3s ease-in-out;
  @media (max-width: 1600px) {
    padding: 60px;
  }
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 0;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }

  p {
    text-align: justify;
  }
  h2 {
    font-size: 21px;
    font-weight: 500;
    color: ${themeGet('colors.textColor', '#292929')};
    margin-bottom: 40px;
    @media (max-width: 990px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }
`;
export const SocialProfiles = styled.div`
  margin-top: 60px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 40px;
  }

  &:before {
    content: '';
    width: 30px;
    height: 1px;
    background: #292929;
    display: block;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;
