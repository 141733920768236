import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

export const LandingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  background: #fff;
`;

export const LandingDetails = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  background-color: #fff;
  padding: 0 60px 0 0;
  transition: 0.3s ease-in-out;
  font-size: ${themeGet('fontSizes.3', '15')}px;
  color: ${themeGet('textColor', '#292929')};
  font-weight: 400;
  line-height: 2;
  text-align: justify;
  @media (max-width: 1024px) {
    padding: 0 20px 0 0;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
`;

export const LandingDescriptionTitle = styled.h2`
  font-size: 48px;
  font-weight: 700;
  color: ${themeGet('colors.textColor', '#292929')};
  margin-bottom: 40px;
  line-height: 1.41;
  a {
    color: ${themeGet('colors.textColor', '#292929')};
  }
  @media (max-width: 1600px) {
    font-size: 34px;
    margin-bottom: 35px;
  }
  @media (max-width: 1024px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
  @media (max-width: 990px) and (min-width: 768px) {
    font-size: 20px;
    max-height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const LandingImage = styled.div`
  position: relative;
  overflow: hidden;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 600px) {
    max-height: 0em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  padding-top: 90px;
  transition: 0.3s ease-in-out;
  @media (max-width: 1600px) {
    padding-top: 60px;
  }
  @media (max-width: 1024px) {
    padding-top: 20px;
  }
`;
