import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.css';
import PostSliderWrapper from './style';

const SimpleSwiper: React.FunctionComponent = () => {
  const Data = useStaticQuery(graphql`
    query imageQuery {
      source: allFile(filter: { absolutePath: { regex: "/slides/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const Slides = Data.source.edges;

  const params = {
    navigation: {
      prevEl: '.ps-button-prev',
      nextEl: '.ps-button-next',
    },
    // renderPrevButton: () => (
    // 	<PrevButton className="ps-button-prev" aria-label="prev">
    // 		<svg
    // 			xmlns="http://www.w3.org/2000/svg"
    // 			width="20"
    // 			height="10.545"
    // 			viewBox="0 0 20 10.545"
    // 		>
    // 			<path
    // 				id="left-arrow"
    // 				d="M4.892,4.835a.532.532,0,0,1,.756.748L1.816,9.415H19.462A.532.532,0,0,1,20,9.944a.539.539,0,0,1-.537.537H1.816l3.833,3.825a.543.543,0,0,1,0,.756.53.53,0,0,1-.756,0l-4.74-4.74a.533.533,0,0,1,0-.748Z"
    // 				transform="translate(0.001 -4.676)"
    // 				fill="#fff"
    // 			/>
    // 		</svg>
    // 	</PrevButton>
    // ),
    // renderNextButton: () => (
    // 	<NextButton className="ps-button-next" aria-label="next">
    // 		<svg
    // 			xmlns="http://www.w3.org/2000/svg"
    // 			width="20"
    // 			height="10.545"
    // 			viewBox="0 0 20 10.545"
    // 		>
    // 			<path
    // 				id="right-arrow"
    // 				d="M15.106,4.835a.532.532,0,1,0-.756.748l3.833,3.833H.536A.532.532,0,0,0,0,9.944a.539.539,0,0,0,.537.537H18.183L14.35,14.307a.543.543,0,0,0,0,.756.53.53,0,0,0,.756,0l4.74-4.74a.533.533,0,0,0,0-.748Z"
    // 				transform="translate(0.001 -4.676)"
    // 				fill="#fff"
    // 			/>
    // 		</svg>
    // 	</NextButton>
    // ),
    spaceBetween: 0,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 600,
  };

  return (
    <PostSliderWrapper>
      <Swiper {...params}>
        {Slides.map(({ node }: any, i: number) => (
          <div key={i}>
            <Img fluid={node.childImageSharp.fluid} alt={'Slide-' + i} />
          </div>
        ))}
      </Swiper>
    </PostSliderWrapper>
  );
};

export default SimpleSwiper;
