import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Title } from '../../../theme/custom-variant';
import { LandingDetails, LandingImage, LandingWrapper } from './style';

export const LandingDescription: React.FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
  // @ts-ignore
  const intl = useIntl();
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/landing.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <LandingWrapper>
      <LandingDetails>
        <Title>
          <FormattedHTMLMessage id="landing_description.title" />
        </Title>
        <FormattedHTMLMessage id="landing_description.description" />
      </LandingDetails>
      <LandingImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="landing" />
      </LandingImage>
    </LandingWrapper>
  );
};
