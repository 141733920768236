import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PersonalBlog from '../containers/home';

const HomePage: React.FunctionComponent<any> = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <PersonalBlog />
    </Layout>
  );
};

export default HomePage;
