import * as React from 'react';
import InstagramShowcase from '../instagram-showcase';
import Events from './events';
import Intro from './intro';
import { LandingDescription } from './landing-description';
import SimpleSwiper from './post-slider';
import PersonalBlogWrapper, { HomeElement } from './style';

type PersonalBlogProps = unknown;

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = ({ ...props }) => {
  return (
    <PersonalBlogWrapper {...props}>
      <SimpleSwiper />
      <HomeElement>
        <Intro />
      </HomeElement>
      <HomeElement>
        <LandingDescription />
      </HomeElement>
      {/*<HomeElement>*/}
      {/*  <Video />*/}
      {/*</HomeElement>*/}
      <HomeElement>
        <Events />
      </HomeElement>
      <HomeElement>
        <InstagramShowcase />
      </HomeElement>
    </PersonalBlogWrapper>
  );
};

export default PersonalBlog;
