import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import SocialProfile from '../../../components/social-profile/social-profile';
import { Title } from '../../../theme/custom-variant';
import { SocialLinks } from '../../about';
import { Desciption, IntroImage, IntroInfo, IntroWrapper } from './style';

const Intro: React.FunctionComponent = () => {
  const intl = useIntl();

  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 210, maxHeight: 210, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const AuthorImage = Data.avatar.childImageSharp.fluid;

  return (
    <IntroWrapper>
      <IntroImage>
        <Image fluid={AuthorImage} alt="author" />
      </IntroImage>
      <IntroInfo>
        <Title>{intl.formatMessage({ id: 'intro.title' })}</Title>
        <Desciption>
          <FormattedHTMLMessage id="intro.description" />
        </Desciption>
        <SocialProfile items={SocialLinks} />
      </IntroInfo>
    </IntroWrapper>
  );
};

export default Intro;
