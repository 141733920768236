import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { IoIosAt, IoIosPhonePortrait, IoLogoFacebook, IoLogoInstagram, IoLogoYoutube } from 'react-icons/io';
import SocialProfile from '../../components/social-profile/social-profile';
import { Title } from '../../theme/custom-variant';
import { AboutDetails, AboutImage, AboutPageTitle, AboutWrapper, SocialProfiles } from './style';

export const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/AcroMindfully',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/acromindfully/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoYoutube />,
    url: 'https://www.youtube.com/channel/UCek-h3bFlj0zH1cD_dHrmuQ/featured/',
    tooltip: 'YouTube',
  },
  {
    icon: <IoIosAt />,
    url: 'mailto:acromindfully@outlook.com',
    tooltip: 'Email',
  },
  {
    icon: <IoIosPhonePortrait />,
    url: 'tel:+393333496861',
    tooltip: 'Tel',
  },
];

const About: React.FunctionComponent = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const intl = useIntl();
  return (
    <AboutWrapper>
      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>
      <AboutDetails>
        <AboutPageTitle>
          <Title>{intl.formatMessage({ id: 'about.title' })}</Title>
        </AboutPageTitle>
        <p>
          <FormattedHTMLMessage id="about.desc" />
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
