import React from 'react';
import Footer from '../components/footer/footer';
import Navbar from '../components/navbar/navbar';
import ResetCss from '../components/reset-css';
import SEO from '../components/seo';
import NotFound from '../containers/not-found';

const NotFoundPage: React.FunctionComponent<any> = () => {
  return (
    <>
      <ResetCss />
      <Navbar />
      <SEO title="404: Not Found" />
      <NotFound />
      <Footer>Copyright &copy; {new Date().getFullYear()} AcroMindfully</Footer>
    </>
  );
};

export default NotFoundPage;
