import styled from 'styled-components';

const EventsWrapper = styled.div`
  position: relative;
`;

export const EventRow = styled.div`
  margin: 0 -15px;
  @media (max-width: 990px) {
    margin: 0 -10px;
  }
`;

export const EventCol = styled.div`
  width: 50%;
  float: left;
  padding: 0 15px;
  @media (max-width: 990px) {
    padding: 0 10px;
  }
  @media (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export default EventsWrapper;
