import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Masonry from 'react-masonry-component';
import MasonryCard from '../../../components/masonry-card/masonry-card';

import EventsWrapper, { EventCol, EventRow } from './style';

type EventsProps = unknown;

const Events: React.FunctionComponent<EventsProps> = () => {
  const intl = useIntl();
  const Data = useStaticQuery(graphql`
    query {
      corsi: file(absolutePath: { regex: "/corsi_torino.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      workshop: file(absolutePath: { regex: "/workshop.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <EventsWrapper>
      <EventRow>
        <Masonry className="showcase">
          <EventCol>
            <MasonryCard
              title={intl.formatMessage({ id: 'events.courses_title' })}
              excerpt={intl.formatHTMLMessage({ id: 'events.courses_description' }) as any}
              image={Data.corsi.childImageSharp.fluid}
              url="https://www.facebook.com/AcroMindfully/events/"
            />
          </EventCol>
          <EventCol>
            <MasonryCard
              title={intl.formatMessage({ id: 'events.ws_title' })}
              excerpt={intl.formatHTMLMessage({ id: 'events.ws_description' }) as any}
              image={Data.workshop.childImageSharp.fluid}
              url="https://www.facebook.com/AcroMindfully/events/"
            />
          </EventCol>
        </Masonry>
      </EventRow>
    </EventsWrapper>
  );
};

export default Events;
