import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/about';

const AboutPage: React.FunctionComponent = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'about.title' })}
        description={intl.formatMessage({ id: 'website_description' })}
      />
      <About />
    </Layout>
  );
};

export default AboutPage;
