import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../containers/contact';

// eslint-disable-next-line @typescript-eslint/ban-types
type ContactPageProps = {};

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'contact' })}
        description={intl.formatMessage({ id: 'contact.description' })}
      />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
