import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Title } from '../../theme/custom-variant';
import { Goback, Icon, NotFoundContent, NotFoundImage, NotFoundWrapper } from './style';

const NotFound: React.FunctionComponent = () => {
  const intl = useIntl();
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/404.png/" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <Title>{intl.formatMessage({ id: '404.title' })}</Title>
        <p>{intl.formatMessage({ id: '404.desc' })}</p>
        <Goback>
          <Link to="/">
            <Icon>
              <IoMdArrowRoundBack />
            </Icon>
            {intl.formatMessage({ id: '404.link' })}
          </Link>
        </Goback>
      </NotFoundContent>
      <NotFoundImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </NotFoundImage>
    </NotFoundWrapper>
  );
};

export default NotFound;
