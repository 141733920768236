import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { Title } from '../../theme/custom-variant';
import { MasonryCardWrapper, PostDetails, PostExcerpt, PostPreview, ReadMore } from './masonry-card.style';

interface MasonryCardProps {
  image?: any;
  title: string;
  excerpt: string;
  url: string;
  className?: string;
  imageType?: 'fixed' | 'fluid';
}

const MasonryCard: React.FunctionComponent<MasonryCardProps> = ({
  image,
  title,
  excerpt,
  url,
  className,
  imageType,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['mesonry_card'];
  const intl = useIntl();

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <MasonryCardWrapper className={addAllClasses.join(' ')} {...props}>
      {image == null ? null : (
        <PostPreview className="post_preview">
          {imageType === 'fluid' ? <Img fluid={image} alt="event" /> : <Img fixed={image} alt="event" />}
        </PostPreview>
      )}

      <PostDetails className="post_details">
        <Title className="post_title">{title}</Title>
        <PostExcerpt
          dangerouslySetInnerHTML={{
            __html: excerpt,
          }}
        />
        <ReadMore>
          <a href={url} target="_blank">
            {intl.formatMessage({ id: 'events.link' })}
          </a>
        </ReadMore>
      </PostDetails>
    </MasonryCardWrapper>
  );
};

MasonryCard.defaultProps = {
  imageType: 'fluid',
};

export default MasonryCard;
