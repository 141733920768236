import { useIntl } from 'gatsby-plugin-intl';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useState } from 'react';
import { Title } from '../../theme/custom-variant';
import Button from '../button/button';
import Input from '../input/input';
import {
  ErrorMessage,
  NewsletterDescription,
  NewsletterInnerWrapper,
  NewsletterInputWrapper,
  NewsletterWrapper,
  SuccessMessage,
} from './newsletter.style';

type NewsletterProps = any;

const Newsletter: React.FunctionComponent<NewsletterProps> = (props) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };
  const intl = useIntl();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(({ msg, result }: any) => {
        if (result !== 'success') {
          throw msg;
        }
        setSuccess(msg);
        setError('');
        setEmail('');
      })
      .catch((err: any) => {
        setError(err);
        setSuccess('');
        setEmail('');
      });
  };
  return (
    <NewsletterWrapper {...props}>
      <NewsletterInnerWrapper>
        <Title>{intl.formatMessage({ id: 'newsletter.title' })}</Title>
        <NewsletterDescription>{intl.formatMessage({ id: 'newsletter.description' })}</NewsletterDescription>

        <NewsletterInputWrapper onSubmit={handleSubmit}>
          {success ? (
            <SuccessMessage>{intl.formatMessage({ id: 'newsletter.success' })}</SuccessMessage>
          ) : (
            <>
              <Input
                type="email"
                name="email"
                placeholder={intl.formatMessage({ id: 'newsletter.placeholder' })}
                onChange={handleChange}
                value={email}
                required={true}
              />
              <Button title={intl.formatMessage({ id: 'newsletter.button' })} type="submit" />
            </>
          )}
        </NewsletterInputWrapper>
        {error && <ErrorMessage dangerouslySetInnerHTML={{ __html: `<span>*</span>${error}` }} />}
      </NewsletterInnerWrapper>
    </NewsletterWrapper>
  );
};

export default Newsletter;
