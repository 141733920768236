import axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import * as Yup from 'yup';
import Button from '../../components/button/button';
import Input from '../../components/input/input';
import { Title } from '../../theme/custom-variant';
import { ContactFromWrapper, ContactPageTitle, ContactWrapper, InputGroup } from './style';

interface MyFormValues {
  firstName: string;
  email: string;
  message: string;
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string().required('Required'),
});

const Contact: React.FunctionComponent<any> = () => {
  const intl = useIntl();
  return (
    <Formik
      initialValues={{ firstName: '', email: '', message: '' }}
      onSubmit={(values: MyFormValues, actions: any) => {
        axios({
          method: 'post',
          url: 'https://getform.io/f/6a5c44a7-5d04-4957-9fe7-02a137dbb80a',
          data: values,
        });
        actions.setSubmitting(false);
        actions.resetForm();
      }}
      validationSchema={SignupSchema}
    >
      {({ handleChange, values, errors, handleBlur, touched, isSubmitting }: FormikProps<MyFormValues>) => (
        <>
          <Form>
            <ContactWrapper>
              <ContactPageTitle>
                <Title>{intl.formatMessage({ id: 'contact' })}</Title>
                <p>{intl.formatMessage({ id: 'contact.long_description' })}</p>
              </ContactPageTitle>
              <ContactFromWrapper>
                <InputGroup>
                  <Input
                    type="text"
                    name="firstName"
                    value={`${values.firstName}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={intl.formatMessage({ id: 'contact.form_name' })}
                    notification={`${errors.firstName && touched.firstName ? errors.firstName : ''}`}
                  />
                  <Input
                    type="email"
                    name="email"
                    value={`${values.email}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    notification={`${errors.email && touched.email ? errors.email : ''}`}
                  />
                </InputGroup>
                <Input
                  type="textarea"
                  name="message"
                  value={`${values.message}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={intl.formatMessage({ id: 'contact.form_msg' })}
                  notification={errors.message && touched.message ? errors.message : ''}
                />
                <div className="center">
                  <Button
                    title={intl.formatMessage({ id: 'contact.form_sand' })}
                    type="submit"
                    isLoading={isSubmitting}
                    loader="Submitting.."
                  />
                </div>
                <br />
                <br />
              </ContactFromWrapper>
            </ContactWrapper>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Contact;
