import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Helmet from 'react-helmet';

type SEOProps = {
  meta?: any;
  keywords?: any;
  title?: any;
  description?: any;
};

const SEO: React.FunctionComponent<SEOProps> = ({ meta, keywords, title, description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `,
  );

  const intl = useIntl();
  const pageTitle = title || site.siteMetadata.title;
  const author = site.siteMetadata.author;
  const pageDescription = description || intl.formatMessage({ id: 'website_description' });

  return (
    <Helmet
      htmlAttributes={{ lang: intl.locale }}
      title={pageTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: pageDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: pageDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: pageDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  meta: [],
  keywords: [
    'Acroyoga',
    'AcroYoga Torino',
    'Acro',
    'Yoga',
    'Partneryoga',
    'Partner Acrobatics',
    'Acrobatics',
    'AcroYoga Teacher',
  ],
};

export default SEO;
