import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

export const PersonalBlogWrapper = styled.div`
  a {
    color: ${themeGet('colors.textColor', '#292929')};
    font-weight: bold;
  }
  a:hover {
    color: ${themeGet('colors.yellow', '#F1873A')};
  }
`;

export const HomeElement = styled.div`
  padding: 20px 75px 20px 75px;
  @media (max-width: 1400px) {
    padding: 20px 45px 20px 45px;
  }
  @media (max-width: 990px) {
    padding: 10px 25px 10px 25px;
  }
`;

export default PersonalBlogWrapper;
