import { changeLocale, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Flag from 'react-flagkit';
import LogoImage from '../../images/logo.png';
import { DrawerProvider } from '../drawer/drawer-context';
import Menu from './menu';
import MobileMenu from './mobile-menu';
import HeaderWrapper, { Logo, MenuWrapper, NavbarWrapper } from './navbar.style';

type NavbarProps = {
  className?: string;
};

const Navbar: React.FunctionComponent<NavbarProps> = ({ className, ...props }) => {
  // Add all classs to an array
  const addAllClasses = ['header'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }
  const intl = useIntl();

  const MenuItems = [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: intl.formatMessage({ id: 'about.title' }),
      url: '/about',
    },
    {
      label: intl.formatMessage({ id: 'contact' }),
      url: '/contact',
    },
  ];

  return (
    <HeaderWrapper className={addAllClasses.join(' ')} {...props}>
      <NavbarWrapper className="navbar">
        <DrawerProvider>
          <MobileMenu items={MenuItems} logo={LogoImage} />
        </DrawerProvider>
        <Logo>
          <Link to="/">
            <img src={LogoImage} alt="logo" />
          </Link>
        </Logo>
        <MenuWrapper>
          <Menu items={MenuItems} />
          <a
            key="it"
            onClick={() => changeLocale('it')}
            style={{
              margin: 10,
              cursor: `pointer`,
            }}
          >
            <Flag country="IT" />
          </a>
          <div>&nbsp;&nbsp;</div>
          <a
            key="en"
            onClick={() => changeLocale('en')}
            style={{
              margin: 10,
              cursor: `pointer`,
            }}
          >
            <Flag country="US" />
          </a>
        </MenuWrapper>
      </NavbarWrapper>
    </HeaderWrapper>
  );
};

export default Navbar;
