import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { variant } from 'styled-system';

const buttonStyle = variant({
  key: 'buttonStyles',
});

const colorStyle = variant({
  key: 'colorStyles',
  prop: 'colors',
});

const buttonSize = variant({
  key: 'buttonSize',
  prop: 'size',
});

export const Title = styled.h1`
  font-size: 35px;
  font-weight: 400;
  color: ${themeGet('colors.textColor', '#292929')};
  line-height: 1.65;
  font-family: ${themeGet('fontFamily.1', "'Poppins', sans-serif")};
  margin-bottom: 20px;
  @media (max-width: 990px) {
    font-size: 26px;
    margin-bottom: 15px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
  }
`;

export { buttonStyle, colorStyle, buttonSize };
